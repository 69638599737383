import React, { Suspense, lazy } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

/* loader component for Suspense*/
import PageLoader from "./components/Common/PageLoader";

import Base from "./components/Layout/Base";
import BasePage from "./components/Layout/BasePage";
// import BaseHorizontal from './components/Layout/BaseHorizontal';

/* Used to render a lazy component with react-router */
const waitFor = (Tag) => (props) => <Tag {...props} />;

const SingleView = lazy(() => import("./components/SingleView/SingleView"));
const SubMenu = lazy(() => import("./components/SubMenu/SubMenu"));

// CUSTOM VIEW

const testView = lazy(() => import("./components/Views/TestViews"));
const specieAnimalsListView = lazy(() =>
  import("./components/Views/AnimalManagement/SpecieAnimalListView")
);

const AssesmentView = lazy(() => import("./components/Views/AssesmentView"));
const animalManagementView = lazy(() =>
  import("./components/Views/AnimalManagement/AnimalManagementView")
);
const AnimalStatView = lazy(() =>
  import("./components/Views/AnimalManagement/AnimalStatsView")
);
const GroupListView = lazy(() =>
  import("./components/Views/AnimalManagement/GroupListView")
);

const SocialView = lazy(() =>
  import("./components/Views/SocialManagement/SocialView")
);

const SpecieListManagement = lazy(() =>
  import("./components/Views/SpecieManagement/SpecieListManagementView")
);
const SpecieManagementView = lazy(() =>
  import("./components/Views/SpecieManagement/SpecieManagementView")
);
const SpecieQA = lazy(() =>
  import("./components/Views/SpecieManagement/SpecieQA")
);

const FamilyListManagement = lazy(() =>
  import("./components/Views/FamilyListManagementView")
);
const OrderListManagement = lazy(() =>
  import("./components/Views/OrderListManagementView")
);

const ReportListView = lazy(() =>
  import("./components/Views/reports/reportListView")
);
const ReportManagementView = lazy(() =>
  import("./components/Views/reports/ReportManagementView")
);

const EnclosureListView = lazy(() =>
  import("./components/Views/EnclosureManagement/EnclosureListView")
);
const EnclosureManagementView = lazy(() =>
  import("./components/Views/EnclosureManagement/EnclosureManagementView")
);
const EnclosureSensorView = lazy(() =>
  import("./components/Views/EnclosureManagement/EnclosureSensorView")
);

const EnrichListView = lazy(() =>
  import("./components/Views/Enrichissements/EnrichListView/EnrichListView")
);
const EnrichManagementView = lazy(() =>
  import("./components/Views/Enrichissements/EnrichView/EnrichView")
);
const EnrichListHistory = lazy(() =>
  import(
    "./components/Views/Enrichissements/EnrichListHistory/EnrichListHistory"
  )
);

const GroupManagementView = lazy(() =>
  import(
    "./components/Views/SiteManagement/GroupManagement/GroupManagementView"
  )
);
const CampaignCreationView = lazy(() =>
  import("./components/Views/CampaignManagement/CampaignCreationView")
);
const campaignListView = lazy(() =>
  import(
    "./components/Views/CampaignManagement/CampaignListView/CampaignListView"
  )
);
const CampaignManagementView = lazy(() =>
  import(
    "./components/Views/CampaignManagement/CampaignManagementView/CampaignManagementView"
  )
);
const CampaignQuizzMonitoringView = lazy(() =>
  import(
    "./components/Views/CampaignManagement/CampaignQuizzMonitoring/CampaignQuizzMonitoringView"
  )
);

const userCreation = lazy(() =>
  import("./components/Views/UsersManagement/userCreation")
);
const userSettings = lazy(() =>
  import("./components/Views/UsersManagement/userSetting")
);

const CirManagementView = lazy(() =>
  import("./components/Views/CIR/cirManagementView")
);

const ResearchView = lazy(() =>
  import(
    "./components/Views/research/researchManagementView/researchManagementView"
  )
);
const ResearchListView = lazy(() =>
  import("./components/Views/research/researchListView/researchListView")
);

const QuestionManagementView = lazy(() =>
  import(
    "./components/Views/Quizz/QuestionManagementView/QuestionManagementView"
  )
);
const QuestionListView = lazy(() =>
  import("./components/Views/Quizz/QuestionListView/QuestionListView")
);
const QuestionShortManagement = lazy(() =>
  import("./components/Views/Quizz/QuestionListView/QuestionShortManagement")
);

const ObsListView = lazy(() =>
  import("./components/Views/ObsManagement/ObsListView/ObsListView")
);
const ObsManagementView = lazy(() =>
  import("./components/Views/ObsManagement/ObsManagementView/ObsManagementView")
);
const ObsResultView = lazy(() =>
  import("./components/Views/ObsManagement/ObsResultView/ObsResultView")
);
const ObsPreview = lazy(() =>
  import("./components/Views/ObsManagement/ObsPreview/ObsPreview")
);
const ObsSessionListView = lazy(() =>
  import(
    "./components/Views/ObsManagement/ObsSessionListView/ObsSessionListView"
  )
);
const ObsSessionView = lazy(() =>
  import("./components/Views/ObsManagement/ObsSessionListView/ObsSessionView")
);
const ObsComparisonView = lazy(() =>
  import("./components/Views/ObsManagement/ObsComparisonView/ObsComparisonView")
);
const ObsContextView = lazy(() =>
  import("./components/Views/ObsManagement/ObsContext/ObsContextView")
);
const ObsContextListView = lazy(() =>
  import("./components/Views/ObsManagement/ObsContext/ObsContextListView")
);
const InterObsView = lazy(() =>
  import("./components/Views/ObsManagement/ObsInterUser/InterObsView")
);
const obsListViewReadOnly = lazy(() =>
  import(
    "./components/Views/ObsManagement/ObsListViewReadOnly/ObsListViewReadOnly"
  )
);
const obsPreview = lazy(() =>
  import("./components/Views/ObsManagement/ObsPreview/ObsPreview")
);

const sectorListView = lazy(() =>
  import("./components/Views/SectorsManagement/SectorListView")
);
const SectorManagementView = lazy(() =>
  import("./components/Views/SectorsManagement/SectorManagementView")
);

const quizzManagementView = lazy(() =>
  import("./components/Views/Quizz/QuizzManagementView/QuizzManagementView")
);
const quizzManagementView2 = lazy(() =>
  import("./components/Views/Quizz/QuizzManagementView/QuizzManagementView2")
);
const ProfilComparisonView = lazy(() =>
  import("./components/Views/Quizz/QuizzManagementView/ProfilComparisonView")
);

const quizzListView = lazy(() =>
  import("./components/Views/Quizz/QuizzListView/QuizzListView")
);
const quizzListView2 = lazy(() =>
  import("./components/Views/Quizz/QuizzListView/QuizzListView2")
);
const quizzListView3 = lazy(() =>
  import("./components/Views/Quizz/QuizzListView/QuizzListView3")
);
const BiblioView = lazy(() =>
  import("./components/Views/Quizz/QuizzListView/BiblioView")
);

const QuizzProfileView = lazy(() =>
  import("./components/Views/Quizz/QuizzListView/QuizzProfilView")
);
const QuizzListViewReadOnly = lazy(() =>
  import("./components/Views/Quizz/QuizzListView/QuizzListReadOnlyView")
);
const QuizzResultView = lazy(() =>
  import("./components/Views/Quizz/QuizzResultView/QuizzResultView")
);
const QuizzResultDemo = lazy(() =>
  import("./components/Views/Quizz/QuizzResultView/QuizzResult_demo")
);
const EvalHistory = lazy(() =>
  import("./components/Views/Quizz/EvalHistory/EvalHistory")
);

const QuizzPreview = lazy(() =>
  import("./components/Views/Quizz/QuizzResultView/QuizzPreview")
);
const QuizzPreview2 = lazy(() =>
  import("./components/Views/Quizz/QuizzResultView/QuizzPreview2")
);
const QuizzPreviewInternal = lazy(() =>
  import("./components/Views/Quizz/QuizzResultView/QuizzPreviewInternal")
);

const QuestionComparisonView = lazy(() =>
  import(
    "./components/Views/Quizz/QuestionComparisonView/QuestionComparisonView"
  )
);
const QuestionTimeMachine = lazy(() =>
  import("./components/Views/Quizz/QuestionTimeMachine/QuestionTimeMachine")
);

const RandomView = lazy(() =>
  import("./components/Views/FakeDataGenerator/RandomView")
);

const Dashboard = lazy(() => import("./components/Views/Dashboard/Dashboard"));
const DashboardSpecie = lazy(() =>
  import("./components/Views/SpecieManagement/DashboardSpecie")
);

const SpecieRecap = lazy(() =>
  import("./components/Views/SpecieManagement/SpecieRecap")
);
const Timeline = lazy(() => import("./components/Views/Timeline/Timeline"));

const GalleryView = lazy(() => import("./components/Views/Media/GalleryView"));

const TagManagementView = lazy(() =>
  import("./components/Views/TagManagement/TagManagementView")
);

const ZooList = lazy(() => import("./components/Views/ZooList/ZooList"));
const ZooDetails = lazy(() => import("./components/Views/ZooList/ZooDetails"));

const Login = lazy(() => import("./components/Views/Login/Login"));
const Register = lazy(() => import("./components/Views/Login/Register"));
// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
  "/login",
  "/Register",
  "/export",
  /* See full project for reference */
];

const Routes = ({ location }) => {
  const currentKey = location.pathname.split("/")[1] || "/";
  const timeout = { enter: 500, exit: 500 };

  // Animations supported
  //      'rag-fadeIn'
  //      'rag-fadeInRight'
  //      'rag-fadeInLeft'

  const animationName = "rag-fadeIn";

  if (listofPages.indexOf(location.pathname) > -1) {
    return (
      // Page Layout component wrapper
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route path="/Register" component={waitFor(Register)} />
            <Route path="/login" component={waitFor(Login)} />
            <Route path="/export" component={waitFor(QuizzPreview2)} />
          </Switch>
        </Suspense>
      </BasePage>
    );
  } else {
    return (
      <Base>
        <TransitionGroup>
          <CSSTransition
            key={currentKey}
            timeout={timeout}
            classNames={animationName}
            exit={false}
          >
            <div>
              <Suspense fallback={<PageLoader />}>
                <Switch location={location}>
                  <Route path="/singleview" component={waitFor(SingleView)} />
                  <Route path="/submenu" component={waitFor(SubMenu)} />
                  <Route path="/testView" component={waitFor(testView)} />
                  <Route
                    path="/specieAnimalsListView"
                    component={waitFor(specieAnimalsListView)}
                  />
                  <Route
                    path="/AssesmentView"
                    component={waitFor(AssesmentView)}
                  />
                  <Route
                    path="/AnimalManagementView"
                    component={waitFor(animalManagementView)}
                  />
                  <Route
                    path="/AnimalStatView"
                    component={waitFor(AnimalStatView)}
                  />
                  <Route
                    path="/GroupListView"
                    component={waitFor(GroupListView)}
                  />
                  <Route path="/SocialView" component={waitFor(SocialView)} />

                  <Route
                    path="/ReportListView"
                    component={waitFor(ReportListView)}
                  />
                  <Route
                    path="/ReportManagementView"
                    component={waitFor(ReportManagementView)}
                  />

                  <Route
                    path="/SpecieListManagement"
                    component={waitFor(SpecieListManagement)}
                  />
                  <Route
                    path="/SpecieManagementView"
                    component={waitFor(SpecieManagementView)}
                  />
                  <Route path="/SpecieQA" component={waitFor(SpecieQA)} />
                  <Route path="/SpecieRecap" component={waitFor(SpecieRecap)} />
                  <Route path="/Timeline" component={waitFor(Timeline)} />
                  <Route
                    path="/FamilyListManagement"
                    component={waitFor(FamilyListManagement)}
                  />
                  <Route
                    path="/OrderListManagement"
                    component={waitFor(OrderListManagement)}
                  />
                  <Route
                    path="/GroupManagementView"
                    component={waitFor(GroupManagementView)}
                  />
                  <Route
                    path="/EnclosureListView"
                    component={waitFor(EnclosureListView)}
                  />
                  <Route
                    path="/EnclosureManagementView"
                    component={waitFor(EnclosureManagementView)}
                  />
                  <Route
                    path="/EnclosureSensorView"
                    component={waitFor(EnclosureSensorView)}
                  />
                  <Route
                    path="/EnrichListView"
                    component={waitFor(EnrichListView)}
                  />
                  <Route
                    path="/EnrichManagementView"
                    component={waitFor(EnrichManagementView)}
                  />
                  <Route
                    path="/EnrichListHistory"
                    component={waitFor(EnrichListHistory)}
                  />
                  <Route
                    path="/CirManagementView"
                    component={waitFor(CirManagementView)}
                  />
                  <Route
                    path="/SectorListView"
                    component={waitFor(sectorListView)}
                  />
                  <Route
                    path="/SectorManagementView"
                    component={waitFor(SectorManagementView)}
                  />
                  <Route
                    path="/CampaignCreationView"
                    component={waitFor(CampaignCreationView)}
                  />
                  <Route
                    path="/campaignListView"
                    component={waitFor(campaignListView)}
                  />
                  <Route
                    path="/CampaignManagementView"
                    component={waitFor(CampaignManagementView)}
                  />
                  <Route
                    path="/CampaignQuizzMonitoringView"
                    component={waitFor(CampaignQuizzMonitoringView)}
                  />
                  <Route
                    path="/userCreation"
                    component={waitFor(userCreation)}
                  />
                  <Route
                    path="/userSettings"
                    component={waitFor(userSettings)}
                  />
                  <Route
                    path="/ResearchView"
                    component={waitFor(ResearchView)}
                  />
                  <Route
                    path="/ResearchListView"
                    component={waitFor(ResearchListView)}
                  />
                  <Route
                    path="/quizzManagementView"
                    component={waitFor(quizzManagementView)}
                  />
                  <Route
                    path="/quizzManagementView2"
                    component={waitFor(quizzManagementView2)}
                  />
                  <Route
                    path="/ProfilComparisonView"
                    component={waitFor(ProfilComparisonView)}
                  />
                  <Route
                    path="/quizzListView"
                    component={waitFor(quizzListView)}
                  />
                  <Route
                    path="/quizzListView2"
                    component={waitFor(quizzListView2)}
                  />
                  <Route
                    path="/quizzListView3"
                    component={waitFor(quizzListView3)}
                  />
                  <Route
                    path="/QuizzProfileView"
                    component={waitFor(QuizzProfileView)}
                  />
                  <Route path="/BiblioView" component={waitFor(BiblioView)} />
                  <Route
                    path="/QuizzListViewReadOnly"
                    component={waitFor(QuizzListViewReadOnly)}
                  />
                  <Route
                    path="/quizzResultView"
                    component={waitFor(QuizzResultView)}
                  />
                  <Route
                    path="/QuizzPreview"
                    component={waitFor(QuizzPreview)}
                  />
                  <Route
                    path="/QuizzPreview2"
                    component={waitFor(QuizzPreview2)}
                  />
                  <Route
                    path="/QuizzPreviewInternal"
                    component={waitFor(QuizzPreviewInternal)}
                  />
                  <Route path="/EvalHistory" component={waitFor(EvalHistory)} />
                  <Route
                    path="/QuizzResultDemo"
                    component={waitFor(QuizzResultDemo)}
                  />
                  <Route
                    path="/QuestionManagementView"
                    component={waitFor(QuestionManagementView)}
                  />
                  <Route
                    path="/QuestionComparisonView"
                    component={waitFor(QuestionComparisonView)}
                  />
                  <Route
                    path="/QuestionTimeMachine"
                    component={waitFor(QuestionTimeMachine)}
                  />
                  <Route path="/ObsListView" component={waitFor(ObsListView)} />
                  <Route
                    path="/ObsManagementView"
                    component={waitFor(ObsManagementView)}
                  />
                  <Route
                    path="/ObsResultView"
                    component={waitFor(ObsResultView)}
                  />
                  <Route path="/ObsPreview" component={waitFor(ObsPreview)} />
                  <Route
                    path="/ObsSessionListView"
                    component={waitFor(ObsSessionListView)}
                  />
                  <Route
                    path="/ObsSessionView"
                    component={waitFor(ObsSessionView)}
                  />
                  <Route
                    path="/ObsComparisonView"
                    component={waitFor(ObsComparisonView)}
                  />
                  <Route
                    path="/ObsContextView"
                    component={waitFor(ObsContextView)}
                  />
                  <Route
                    path="/ObsContextListView"
                    component={waitFor(ObsContextListView)}
                  />
                  <Route
                    path="/obsListViewReadOnly"
                    component={waitFor(obsListViewReadOnly)}
                  />
                  <Route path="/obsPreview" component={waitFor(obsPreview)} />
                  <Route
                    path="/InterObsView"
                    component={waitFor(InterObsView)}
                  />
                  <Route path="/Dashboard" component={waitFor(Dashboard)} />
                  <Route
                    path="/DashboardSpecie"
                    component={waitFor(DashboardSpecie)}
                  />
                  <Route
                    path="/QuestionListView"
                    component={waitFor(QuestionListView)}
                  />
                  <Route
                    path="/QuestionShortManagement"
                    component={waitFor(QuestionShortManagement)}
                  />
                  <Route path="/GalleryView" component={waitFor(GalleryView)} />
                  <Route path="/ZooList" component={waitFor(ZooList)} />
                  <Route path="/ZooDetails" component={waitFor(ZooDetails)} />
                  <Route path="/RandomView" component={waitFor(RandomView)} />
                  <Route
                    path="/TagManagementView"
                    component={waitFor(TagManagementView)}
                  />
                  <Route exact path="/">
                    <Redirect to="/Dashboard" />
                  </Route>
                  <Redirect to="/Dashboard" />
                </Switch>
              </Suspense>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Base>
    );
  }
};

export default withRouter(Routes);
